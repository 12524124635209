<template>
  <div class="code-container txt-c" v-if="!noCallCheck">
    <img src="@/assets/img/welcome/phone.svg" />
    <h5>Введите код</h5>
    <p>
      На ваш номер телефона <strong>{{ phone }}</strong> поступит звонок.
      Введите последние {{ length }}&nbsp;цифры входящего номера.
    </p>

    <div class="pure-g container margin-b">
      <div class="pure-u-1-4 form-field code" v-for="(_, i) in length" :key="'code_char_' + i" :class="{ 'error': error }">
        <input :ref="el => codeRefs[i] = el" :modelValue="code[i]" type="text" @keyup="onCodeKeyUp" @input="onUpdated(i, $event)" :maxlength="1" @focus="onCodeFocus(i)" />
      </div>
    </div>

    <p class="text-red" v-if="error">Неверный код. Проверьте код и введите еще раз</p>

    <p class="padding-v">
      <span v-if="seconds > 0">
        Звонок поступит в течение<br/><span>{{ seconds }}&nbsp;сек.</span>
      </span>
      <span class="block margin-v" >
        <a class="dashed" @click.prevent="noCallCheck = true" href="#">Нет звонка?</a>
      </span>
      <a href="#" class="dashed" @click.prevent="this.$emit('back')">Изменить номер</a>
    </p>
  </div>
  <div class="code-container txt-c" v-else>
    <img src="@/assets/img/welcome/question.svg" />
    <h5>Нет звонка?</h5>
    <p>
      Проверьте указанный номер телефона
      <br/>
      <strong>{{ phone }}</strong>
      <br/>
      или запросите код повторно.
      Введите последние {{ length }}&nbsp;цифры входящего номера.
    </p>
    <span class="block margin-v" >
      <a class="dashed" @click.prevent="this.$emit('repeat')" href="#">Запросить код повторно</a>
    </span>
    <a href="#" class="dashed" @click.prevent="this.$emit('back')">Изменить номер телефона</a>
  </div>
</template>

<script>
import { ref } from 'vue'
export default {
  name: 'CodeInput',
  emits: ['update:modelValue', 'back', 'repeat'],
  props: {
    "modelValue": {
      type: String,
    },
    "error": {
      type: Boolean,
      default: false
    },
    "phone": {
      type: String,
    },
    "length": {
      type: Number,
      default: 4
    },
    "repeatTimeout": {
      type: Number,
      default: 69
    }
  },
  data() {
    return {
      keysAllowed: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
      seconds: 0,
      intervalHandler: null,
      noCallCheck: false,
      code: [],
      codeRefs: ref([])
    }
  },
  computed: {
    codeFull() {
      return this.code.length == this.length
    },
    codeNextIndex() {
      return this.codeFull ? this.length - 1 : this.code.filter(c => !!c).length
    },
    codeFiltered() {
      return this.code.filter(c => this.keysAllowed.includes(c))
    }
  },
  mounted() {
    this.seconds = this.repeatTimeout
    this.intervalHandler = setInterval(() => {
      this.seconds--
      if (this.seconds <= 0) {
        clearInterval(this.intervalHandler)
      }
    }, 1000)
  },
  unmounted() {
    clearInterval(this.intervalHandler)
  },
  methods: {
    onUpdated(index, e) {
      const value = e.target.value
      this.code[index] = this.isNumber(value) ? value : ''
      this.code = this.codeFiltered
      
      if (this.code.length == this.length) {
        this.$emit('update:modelValue', this.code.join(''))
      } else {
        this.$emit('update:modelValue', '')
      }
    },
    focusCodeInput(prev) {
      const index = prev ? Math.max(0, this.codeNextIndex - 1) : this.codeNextIndex
      this.codeRefs[index].focus()
    },
    onCodeKeyUp(e) {
      this.code = this.codeFiltered
      if (this.keysAllowed.includes(e.key) || e.keyCode == 8) {
        this.focusCodeInput(e.keyCode == 8 ? true : false)
      }
    },
    onCodeFocus(index) {
      if (this.codeNextIndex >= index) {
        return
      } else {
        this.focusCodeInput()
      }
    },
    isNumber(char) {
      return this.keysAllowed.includes(char)
    }
  }
}
</script>

<style scoped>
h5 {
  margin: .5rem 0 1.5rem;
  font-weight: 700;
  color: #222020;
  font-size: 1.33rem;
}

.phone-mask {
  display: block;
  text-transform: uppercase;
  font-size: 1.75rem;
  margin: .5rem;
}

.highlight {
  color: #359EFF;
}

.phone-mask i {
  position: relative;
  top: .05rem;
}

.form-field.code input {
  background-color: #f5f5f7;
  font-size: 1.5rem;
  padding: .75rem .5rem;
  text-align: center;
}


label {
  display: block;
  position: relative;
  font-weight: 600;
  font-size: .8em;
  margin-bottom: .5em;
  color: #222020;
  background-color: #fff;
  top: 1em;
  left: .5em;
  padding: 0 .5em;
  z-index: 1;
}

.form-field label,
.form-field .hint {
  display: inline-block;
  margin: .5em;
}
</style>