<template>
  <footer class="pure-g container max-width">
    <div class="pure-u-1 pure-u-md-5-24 pure-u-lg-1 pure-u-xl-4-24">
      <h4>
        <img class="logo" alt="Kvisto logo" src="@/assets/logo.svg">
      </h4>
    </div>
    <div class="pure-u-1-2 pure-u-md-10-24 pure-u-lg-7-24 pure-u-xl-6-24">
      <h4>Юридическая информация</h4>
      <router-link :to="{name: 'Privacy'}">Конфиденциальность</router-link>
      <router-link :to="{name: 'Terms'}">Условия</router-link>
    </div>
    <div class="pure-u-1-2 pure-u-md-9-24 pure-u-lg-6-24 pure-u-xl-5-24">
      <h4 class="help-title">Помощь</h4>
      <a href="#">Частые вопросы</a>
      <a href="#">Помощь с аккаунтом</a>
    </div>
    <div class="pure-u-1 pure-u-md-5-24 hidden-lg hidden-xl"></div>
    <div class="pure-u-1-2 pure-u-md-10-24 pure-u-lg-6-24 pure-u-xl-5-24">
      <h4>Наша информация</h4>
      <a href="#">О нас</a>
      <a href="#">Связь с нами</a>
      <a href="#">Советы по безопасности</a>
    </div>
    <div class="pure-u-1-2 pure-u-md-9-24 pure-u-lg-5-24 pure-u-xl-4-24 socials txt-r-lg txt-r-xl">
      <h4>Социальные сети</h4>
      <a href="#"><i class="icon-instagram"></i></a>
      <a href="#"><i class="icon-facebook"></i></a>
      <a href="#"><i class="icon-vk"></i></a>
    </div>
    <div class="pure-u-1 hint margin-t">Copyright 2011-2025. All rights reserved</div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>
footer {
  padding: 2em 0 3em;
}
body footer h4,
body footer a {
  font-size: 1rem;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 400;
}
body footer h4 {
  font-weight: 500;
  font-size: 1.2em 0;
}

.logo {
  height: 1.5em;
}

footer a,
footer a:active,
footer a:visited {
  display: block;
  padding: .5rem 0;
  text-decoration: none;
  color: #504F4F;
}
footer a:hover {
  color: #000;
}
footer .socials a {
  display: inline-block;
  padding: .5rem 2.1rem .5rem 0;
  font-size: 1.33em;
}

/* lg+ */
@media screen and (min-width: 64em) {
  footer .socials a {
    padding: .5rem 0 .5rem 2.1rem;
  }
}

@media screen and (max-width: 497px) {
  .help-title {
    margin-bottom: 2.45rem;
  }
  footer .socials a {
    display: inline-block;
    padding-right: 1.8rem;
    font-size: 1.33em;
  }
}
</style>
