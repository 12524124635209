<template>
<div>
  <file-upload class="hidden" name="file" :post-action="uploadUrl" :multiple="true"
      :drop="false" :thread="1" :headers="headers" :input-id="uploadInputId" accept="image/png,image/jpeg"
      v-model="files" ref="upload" @input-filter="inputFile"/>

  <div class="padding-half-h" v-show="files.length > 0">
    <div class="pure-g container-sm files-container" v-show="!isUploading">
      <template v-for="file in files">
        <div class="pure-u-1-5 margin-half-v file uploading"
          v-if="!file.success || file.response.err_code != 0" :key="file.id + 'uploading'">
          <div class="photo photo-cover" :style="{ 'background-image': 'url(' + file.url + ')' }" :class="{
            'error': file.response.err_code && file.response.err_code != 0
          }">
            <div class="top padding-half" v-if="!isUploading && file.response.err_code == 2">
              <i class="icon-exclamation-circle"></i>
            </div>
            <div class="top txt-r" v-if="!isUploading && file.progress == 0">
              <button @click="removeFile(file)" class="btn btn-transparent btn-sm"><i
                  class="icon-times"></i></button>
            </div>

          </div>
          <div class="progressbar margin-half-t" :class="{
            'error': file.response.err_code && file.response.err_code != 0
          }" v-if="file.progress > 0">
            <div class="bar" v-bind:style="{ 'width': + file.progress + '%' }"></div>
          </div>
        </div>
      </template>
    </div>
  </div>

  <form method="POST" @submit.prevent="sendCurrentMessage">
    <VTooltip :distance="10" placement="top-start" v-if="!uid || !enableAttachments || files.length >= maxPhotosPerMessage">
      <button class="btn btn-white chat-btn disabled" type="button" :disabled="!uid" @click.prevent><i class="icon-paperclip"></i></button>
      <template #popper>
        <span v-if="!enableAttachments">Сначала вам нужно получить ответ, чтобы отправлять фотографии</span>
        <span v-else-if="files.length >= maxPhotosPerMessage">Вы уже прикрепили максимальное количество фотографий</span>
      </template>
    </VTooltip>
    <label class="btn btn-white chat-btn" type="button" :for="uploadInputId" v-else>
      <i class="icon-paperclip"></i>
    </label>

    <textarea placeholder="Введите сообщение..."
      :disabled="!uid" class="msg-input"
      ref="msgInput"
      v-model="msg"
      @keydown.enter="onEnterKeydown"
      @keydown.esc="onEscKeyDown"
      @input="onMessageInput"
      rows="1"
      autofocus></textarea>

    <VMenu placement="top-end">
      <button class="btn btn-white chat-btn" type="button" :disabled="!uid"><i class="icon-smile"></i></button>

      <template #popper>
        <div class="emoji-container">
          <VueChatEmojiComponent
            :open="true"
            width="100%"
            height="100%"
            radius="0"
            background-color="#FFF"
            @handle="emojiPicked" />
        </div>
      </template>
    </VMenu>

    
    <button class="btn btn-white chat-btn"  :disabled="!uid" :class="{
      'active': !isMsgEmpty || msg.length || files.length
    }" type="submit"><i class="icon-send"></i></button>
  </form>
</div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';  

import FileUpload from 'vue-upload-component'

import { VueChatEmojiComponent } from "@nguyenvanlong/vue3-chat-emoji"
import "@nguyenvanlong/vue3-chat-emoji/dist/index.mjs.css"

import {apiCall, apiAuthHeaders, SERVER_ADDRESSES, apiErrCodes, apiEventTypes} from "@/utils/api"
import { pushModal } from "jenesius-vue-modal"
import mixinAutoResize from "@/mixins/autoResize.js"

import { mapGetters } from "vuex";
import PaymentModal from '@/components/modals/payment/PaymentModal.vue'
import TimerModal from '@/components/modals/TimerModal.vue'


export default {
  name: 'ChatForm',
  props: {
    "uid": {
      type: String,
      default: null
    },
    "draft": {
      type: String,
      default: ''
    },
    "enableAttachments": {
      type: Boolean,
      default: false
    }
  },
  mixins: [mixinAutoResize],
  emits: ['send', 'update', 'input'],
  components: {
    VueChatEmojiComponent, FileUpload
  },
  data: function() {
    return {
      msg: "",
      isMsgEmpty: true,
      isTyping: false,
      typingTimeout: null,
      files: [],
      maxPhotosPerMessage: 5,
      isUploading: false,
      uploadToken: '',
      uploadForMessage: null,
      uploadInputId: "chat_upload_input",
    }
  },
  computed: {
    ...mapGetters(["myId"]),
    typingObject() {
      return {
        to_id: this.uid,
        type: apiEventTypes.CHAT_TYPING
      }
    },
    uploadUrl() {
      return SERVER_ADDRESSES.photos + "messages/add-photo"
    },
    headers() {
      return apiAuthHeaders()
    },
    notUploadedImages() {
      return this.files.filter(f => !f.success)
    },
    imagesWithError() {
      return this.files.filter(f => f.response.err_code && f.response.err_code != 0)
    }
  },
  watch: {
    uid: {
      handler() {
        this.msg = this.draft
        this.$nextTick(() => {
          this.$refs.msgInput.focus()
        })
        this.updateAutoHeight()
      },
      immediate: true
    }
  },
  methods: {
    updateAutoHeight() {
      this.$nextTick(() => {
        this.mixin_autoResize_resize(this.$refs.msgInput)
      })
    },
    onEscKeyDown() {
      this.$router.push({ name: 'Relations', params: { relationType: 'messages' } })
    },
    onEnterKeydown(e) {
      if (!e.shiftKey) {
        e.preventDefault();
        this.sendCurrentMessage()
      }
    },
    onMessageInput(e) {
      this.isMsgEmpty = !e.data || e.data.trim().length == 0
      this.mixin_autoResize_resize(e.target)
      this.emitInput()
      if (!this.isTyping) {
        this.startTyping()
      }
    },
    startTyping() {
      this.$socket.sendObj(this.typingObject);
      this.isTyping = true
      clearTimeout(this.typingTimeout)
      this.typingTimeout = setTimeout(this.stopTyping, 4000)
    },
    stopTyping() {
      this.isTyping = false
    },
    emojiPicked(e) {
      // TODO: restore selection
      this.msg = this.insertTextAtCursor(this.$refs.msgInput, e.unicode)
    },
    insertTextAtCursor(field, value) {
      //IE support
      if (document.selection) {
          field.focus();
          var sel = document.selection.createRange();
          sel.text = value;
          return sel.text  // TODO: restore selection
      }
      //MOZILLA and others
      else if (field.selectionStart || field.selectionStart == '0') {
          var startPos = field.selectionStart;
          var endPos = field.selectionEnd;
          field.value = field.value.substring(0, startPos)
              + value
              + field.value.substring(endPos, field.value.length);
      } else {
          field.value += value;
      }
      return field.value  // TODO: restore selection
    },
    createTempMessage(text, photos) {
      let tempMessage = {
        id: null,
        from_id: this.myId,
        to_id: this.uid,
        text: text,
        has_attachments: photos && photos.length,
        photo_count: photos ? photos.length : 0,
        date: null,
        sending_id: uuidv4()
      }
      return tempMessage
    },
    async sendCurrentMessage() {
      if (this.msg.trim() == '' && this.files.length === 0) {
        return
      }

      await this.sendMessage(this.createTempMessage(this.msg, this.files))
      this.msg = '';
      this.emitInput()
    },
    async sendMessage(tempMessage) {
      this.$emit('send', {
        message: tempMessage,
        newDialog: false
      })
      apiCall({ url: SERVER_ADDRESSES.dialogs + tempMessage.from_id + '/' + tempMessage.to_id, method: "POST", data: {
            text: tempMessage.text,
            photo_count: tempMessage.photo_count
          }
      }).then((e) => {
        if (!e.messages) {
          tempMessage.date = new Date().toUTCString()
          tempMessage.err_code = e.err_code
          if (e.err_code == apiErrCodes.PREMIUM_EXPECTED_ERROR) {
            tempMessage.err_message = "Вы достигли лимита диалогов на сегодня"
            pushModal(PaymentModal, {
              type: 'limit_dialogs',
              timeout: e.err_data.timeout
            })
          } else if (e.err_code == apiErrCodes.LIMITS_ERROR) {
            tempMessage.err_message = "Вы достигли лимита диалогов на сегодня"
            pushModal(TimerModal, {
              timeout: e.err_data.timeout
            })
          } else {
            tempMessage.err_message = "Произошла неизвестная ошибка при отправке сообщения"
          }
        }
        
        tempMessage = Object.assign(tempMessage, e.messages ? e.messages[0] : null)
        this.$emit('update', {
          message: tempMessage,
          newDialog: e.new_dialog
        })

        if (e.upload_token && tempMessage.has_attachments && this.files.length) {
          this.uploadToken = e.upload_token
          this.uploadForMessage = tempMessage
          this.uploadForMessage.attachments = []
          this.startUpload()
        }
      })
      this.updateAutoHeight()
    },
    emitInput() {
      this.$nextTick(() => {
        this.$emit('input', this.msg)
      })
    },
    // Uploading photos
    startUpload() {
      this.isUploading = !!this.notUploadedImages.length
      if (this.notUploadedImages.length) {
        this.$refs.upload.update(this.notUploadedImages[0], { active: true })
      }
    },
    removeFile(file) {
      this.$refs.upload.remove(file)
      this.emitInput()
    },
    createTemporaryUrl(file) {
      let URL = window.URL || window.webkitURL
      if (URL && URL.createObjectURL) {
        return URL.createObjectURL(file)
      }
      return ''
    },
    inputFile(newFile, oldFile/*, prevent*/) {
      if (newFile && !oldFile) { // file added
        this.$nextTick(() => {
          if (this.files.length > this.maxPhotosPerMessage) {
            this.removeFile(newFile)
            return
          }
          this.emitInput()
        })
        newFile.url = this.createTemporaryUrl(newFile.file)
      }

      if (newFile && oldFile && newFile.active && !oldFile.active) {
        newFile.data.upload_token = this.uploadToken
      }

      if (newFile && oldFile) {  // file updated
        if (newFile.success !== oldFile.success) {  // Uploaded successfully
          this.$nextTick(() => {
            if (newFile.response.type) {
              this.uploadForMessage.attachments.push(newFile.response)
              delete this.uploadForMessage.sending_id
              this.$emit('update', {
                message: this.uploadForMessage,
                newDialog: false
              })
            }
            this.startUpload()  // start upload net image
            this.removeFile(newFile)
            if (!this.isUploading) { // done
              this.$emit('update')
              // this.$store.dispatch("REQUEST_MY_PHOTOS")
              // this.$store.dispatch("REQUEST_MY_PROFILE")
            }
          })
        }
      }
    },
  }
}
</script>

<style>
.composer-popover.active {
  bottom: 0;
  box-sizing: border-box;
  border: none;
}

.composer-popover-input {
  display: none;
}

.composer-popover-body {
  top: 0;
  padding: .1rem .33rem;
}
</style>

<style scoped>
.file .photo {
  position: relative;
  padding: 0 0 50%;
  border-radius: .5rem;
}

.file .photo.error {
  border: 1px solid #FF3131;
}


.files-container .progressbar {
  height: .25rem;
  background-color: #FFF;
  border-radius: 1rem;
  overflow: hidden;
}

.files-container .progressbar .bar {
  height: 100%;
  background-color: #00C667;
  border-radius: 1rem;
}

.files-container .progressbar.error .bar {
  background-color: #FF3131;
}

form {
  display: flex;
  flex-direction: row;
  border-top: 1px solid #ECECEC;
}

textarea, textarea:focus {
  resize: none;
  border: none;
  outline: none;
}

.msg-input {
  max-height: 7rem;
}

form button {
  height: 3.15rem;
}
form button,
form .btn {
  align-self: flex-start;
}

.emoji-container {
  width: 250px;
  height: 50vh;
  max-height: 20rem;
  overflow: hidden;
}

.chat-btn {
  border-radius: 0;
}

.chat-btn i {
  color: #666;
}

.chat-btn.active i {
  color: #359EFF;
}
</style>