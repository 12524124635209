<template>
  <div class="welcome" :class="{'mobile': isMobileDevice}">
    <div class="content">
      <GridOffset>
        <Header />
        <h1>
          <strong>Знакомься</strong> для дружбы, <strong>назначай</strong> свидания,<br/>
          или <strong>общайся</strong> просто так!
        </h1>
        <div class="pure-g max-width-80">
          <div class="hidden-xs hidden-sm hidden-md pure-u-lg-12-24 pure-u-xl-14-24 left-img block-vcenter">
            <img src="@/assets/img/welcome/people.svg" :style="{'min-height': initialFormHeight}"/>
          </div>
          <div class="hidden-xs hidden-sm pure-u-md-4-24 hidden-lg hidden-xl"></div>
          <div class="pure-u-1 pure-u-md-2-3 pure-u-lg-12-24 pure-u-xl-10-24 margin-b padding-b" ref="formRegistration">
            <FormRegistration />
          </div>
        </div>
      </GridOffset>
    </div>
    <GridOffset>
      <Footer/>
    </GridOffset>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import GridOffset from '@/components/GridOffset.vue'
import FormRegistration from '@/components/registration/FormRegistration.vue'

// mobile
import mixinDevice from "@/mixins/device.js"

export default {
  name: 'Welcome',
  mixins: [mixinDevice],
  components: {
    GridOffset, Header, Footer, FormRegistration,
  },
  data() {
    return {
      initialFormHeight: '100%',
    }
  },
  mounted() {
    this.initialFormHeight = this.$refs.formRegistration.clientHeight + 'px'
  }
}
</script>

<style scoped>
.welcome:not(.mobile) .content {
  background: #FFFCF6 url('../assets/img/welcome/background.svg') no-repeat center;
  background-size: contain;
}
.welcome.mobile .content {
  background: rgb(251,252,255);
  background: radial-gradient(circle, rgba(251,252,255,1) 0%, rgba(245,250,255,1) 54%, rgba(248,244,253,1) 100%);
  min-height: 100vh;
}

h1 {
  font-family: 'Rubik', sans-serif;
  font-weight: 600;
  font-size: 2.18em;
  text-align: center;
  margin: 0em 0 .75em;
}
h1 strong {
  font-weight: 700;
  font-size: 1.3em;
  color: #FF676C;
}
.mobile h1 strong {
  font-size: 1em;
}

/* sm- */
@media screen and (max-width: 47.95em) {
  h1 {
    font-size: 1.7rem;
  }
}

.left-img {
  padding: 0 5% 0 2%;
  margin: 1rem 0 2rem;
}
/* 
.left-img img {
  height: 569px;
} */
</style>