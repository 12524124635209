<template>
  <div>
    <div class="swiper-gallery" v-if="mode === 'single'">
      <Swiper 
        class="slider"
        ref="swiper"
        :modules="swiperModules"
        :navigation="allPhotos && allPhotos.length > 1"
        :loop="false"
        :initialSlide="0"
        :slides-per-view="1"
        :style="{
          'border-radius': swiperRadius
        }"
        @slideChange="onSlideChange">
        <SwiperSlide class="slide" v-for="(p, index) in (allPhotos.length ? allPhotos : [avatar])" :key="'big_photo_' + index">

          <Avatar
            :thumb="p ? p.thumbs.face_md : null"
            :gender="gender"
            :loading="photos === null"
            :hidden="p ? p.is_hidden : false"
            :height="bigPhotoHeight"
            class="photo photo-bigger"
            :class="{ 'gallery-toggle': !!p }"
            @click="galleryItem = index">
            <div class="bottom">
              <template v-if="p && editing">
                <div class="edit-buttons txt-c">
                  <button class="btn btn-link btn-xs" type="button" title="Сделать главной"
                  v-if="p && p.has_face && !p.is_avatar" @click.stop="setAvatar(p)"><i class="icon-photo-main"></i></button>
                  <button class="btn btn-link btn-sm" type="button" title="Удалить фотографию"
                    @click.stop="delPhotoConfirm(p)"><i class="icon-photo-del"></i></button>
                </div>
              </template>
            </div>
          </Avatar>

        </SwiperSlide>
      </Swiper>
      <div class="top">
        <slot name="top" :photo="allPhotos ? allPhotos[photoIndex] : null" :index="photoIndex"></slot>
      </div>
      <div class="bottom">
        <slot name="bottom" :photo="allPhotos ? allPhotos[photoIndex] : null" :index="photoIndex"></slot>
      </div>
    </div>

    <div class="mobile-photos" v-if="mode === 'six'">
      <template v-for="(p, index) in mobilePhotos" :key="'mobile_photo_' + index">
        <Avatar
          :thumb="p ? p.thumbs.face_md : null"
          :gender="gender"
          :loading="photos === null"
          :hidden="p ? p.is_hidden : false"
          class="photo"
          :style="{'border-radius': swiperRadius}"
          :class="{ 'gallery-toggle': !!p }"
          @click="galleryItem = index"
          v-if="index < photoCount">
          <div class="bottom">
            <template v-if="p && editing">
              <div class="edit-buttons txt-c">
                <button class="btn btn-link btn-xs" type="button" title="Сделать главной"
                v-if="p && p.has_face && !p.is_avatar" @click.stop="setAvatar(p)"><i class="icon-photo-main"></i></button>
                <button class="btn btn-link btn-sm" type="button" title="Удалить фотографию"
                  @click.stop="delPhotoConfirm(p)"><i class="icon-photo-del"></i></button>
              </div>
            </template>
          </div>
        </Avatar>
        <div class="photo add-photo" v-else :style="{'border-radius': swiperRadius}" @click="uploadPhotos">
          <i class="icon-plus"></i>
        </div>
      </template>
    </div>

    <div v-if="showMiniatures && (photos || photoCount > 1)" class="pure-g container-sm">
      <div :class="'pure-u-1-' + thumbsCount" v-for="(p, i) in smallPhotos" :key="p ? p.id : 'smallPhoto_' + i">
        <ProfilePhotoMiniature
          class="margin-t photo"
          :photo="p"
          :editing="editing"
          @click="galleryItem = i + 1"
          @setAvatar="setAvatar(p)"
          @delPhoto="delPhotoConfirm(p)"
          />
      </div>
      <div class="margin-t more-photos" :class="'pure-u-1-' + thumbsCount" v-if="otherPhotos.length">
        <Avatar :thumb="otherPhotos[0].thumbs.face_sm" :hidden="false" radius=".4rem" />
        <div class="overlay gallery-toggle" @click="editing ? openMyPhotos() : galleryItem = smallPhotos.length + 1">
          <span>+{{ otherPhotos.length }}</span>
        </div>
      </div>
    </div>
    <Gallery
      v-if="allPhotos.length && galleryItem !== null"
      :uid="uid"
      :photos="allPhotos"
      :index="galleryItem"
      :editing="editing"
      @close="onGalleryClosed"
      @setAvatar="setAvatarFromGallery"
      @delPhoto="delPhotoFromGallery" />
  </div>
</template>

<script>
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue.js';

import 'swiper/swiper.min.css';
import "swiper/modules/navigation/navigation.min.css";

import { pushModal } from "jenesius-vue-modal"
import { mapGetters } from "vuex";
import { apiCall, SERVER_ADDRESSES } from "@/utils/api";

import UploadPhotos from '@/components/modals/UploadPhotos.vue'

import Avatar from '@/components/Avatar.vue'
import ProfilePhotoMiniature from '@/components/ProfilePhotoMiniature.vue'
import ConfirmDelPhoto from '@/components/modals/ConfirmDelPhoto.vue'
import MyPhotosModal from '@/components/modals/MyPhotosModal.vue'
import Gallery from '@/components/Gallery.vue'

// mobile
import mixinDevice from "@/mixins/device.js"

export default {
  mixins: [mixinDevice],
  components: {
    Swiper, SwiperSlide,
    Avatar, ProfilePhotoMiniature, Gallery
  },
  props: {
    "uid": {
      type: String,
      required: true
    },
    "mode": {
      type: String,
      default: "single" // либо "six"
    },
    "photoCount": {
      default: null
    },
    "gender": {
      type: String,
      default: "male"
    },
    "bigPhotoHeight": {
      type: Number,
      default: 120
    },
    "showMiniatures": {
      type: Boolean,
      default: true
    },
    "editing": {
      type: Boolean,
      default: false
    },
    "swiperRadius": {
      type: String,
      default: ".8rem"
    }
  },
  data: function () {
    return {
      photoIndex: 1,
      galleryItem: null,
      thumbsCount: 4,
      swiperModules: [Navigation]
    }
  },
  computed: {
    ...mapGetters(["myId", "myPhotos", "avatarsStore", "photosStore"]),
    allPhotos() {
      if (this.uid == this.myId && this.myPhotos) {
        return this.myPhotos
      } else if (this.uid in this.photosStore) {
        return this.photosStore[this.uid]
      }
      this.$store.dispatch("LOAD_PHOTOS", [this.uid])
      return []
    },
    // всегда 6 фоток для отображения на мобилке, часть из которых может быть null
    mobilePhotos() {
      let photos = this.allPhotos
      if (photos.length > 6) {
        return photos.slice(0, 6)
      }
      return photos.concat(Array(6 - photos.length).fill(null))
    },
    photos() {  // without avatar
      return this.allPhotos.filter(p => !p.is_avatar) 
    },
    avatar() {
      if (!this.allPhotos && this.uid in this.avatarsStore) {
        return this.avatarsStore[this.uid]
      }
      return this.allPhotos.find(p => p.is_avatar)
    },
    smallPhotos: function () {
      if (!this.photos && this.photoCount > 1) {
        return [null, null, null, null]
      } else if (!this.photos) {
        return []
      }
      if (this.photos.length <= this.thumbsCount) {
        return this.photos
      }
      return this.photos.slice(0, 3)
    },
    otherPhotos: function () {
      if (!this.photos || this.photos.length <= this.thumbsCount) {
        return []
      }
      return this.photos.slice(this.thumbsCount - 1)
    },
  },
  methods: {
    getAvatarAfterRemoving(photo) {
      if (photo.id != this.avatar.id) {
        return this.avatar
      }
      return this.photos.find(p => p.has_face && !p.is_avatar)
    },
    async openMyPhotos() {
      const modal = await pushModal(MyPhotosModal)
      modal.on('setAvatar', this.setAvatar)
      modal.on('delPhoto', this.delPhotoConfirm)
    },
    async delPhotoConfirm(photo) {
      const newAvatar = this.getAvatarAfterRemoving(photo)
      const modal = await pushModal(ConfirmDelPhoto, {
        photo: photo,
        warnLast: !newAvatar
      })
      modal.on('confirmed', async () => {
        await this.delPhoto(photo)
        if (!newAvatar) {
          this.$store.dispatch("LOAD_TOP_USERS", {force: true})
        }
      })
    },
    async delPhoto(photo) {
      await apiCall({ url: SERVER_ADDRESSES.photos + this.uid + "/del/" + photo.id, method: "POST" })
      this.$store.dispatch("REQUEST_MY_PHOTOS")
    },
    async setAvatar(photo) {
      await apiCall({ url: SERVER_ADDRESSES.photos + this.uid + "/avatar/" + photo.id, method: "POST" })
      this.$store.dispatch("REQUEST_MY_PHOTOS")
    },
    setAvatarFromGallery(index) {
      this.setAvatar(this.allPhotos[index])
    },
    delPhotoFromGallery(index) {
      this.delPhotoConfirm(this.allPhotos[index])
    },
    onSlideChange(e) {
      if (this.allPhotos.length) {
        this.photoIndex = (e.activeIndex % this.allPhotos.length) + 1
      } else {
        this.photoIndex = 1
      }
    },
    onGalleryClosed(index) {
      this.galleryItem = null
      this.$refs.swiper.$el.swiper.slideTo(index - 1, 0)
    },
    uploadPhotos() {
      pushModal(UploadPhotos, {
        uid: this.uid,
      })
    },
  }
}
</script>


<style>
:root {
  --swiper-navigation-size: 30px;
}
</style>

<style scoped>
.more-photos {
  position: relative;
}

.more-photos .overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: .5rem;
  right: .5rem;
  height: 100%;
  border-radius: .4rem;
  background-color: rgba(0, 0, 0, .5);
}

.more-photos .overlay span {
  color: #FFF;
  font-weight: 700;
  font-size: 1.2rem;
}

.top,
.bottom {
  display: block;
  position: absolute;
  box-sizing: border-box;
  left: 0;
  right: 0;
  margin: 1rem;
  color: #FFF;
  z-index: 1;
}

.top.sm,
.bottom.sm {
  margin: .25rem 0;
}

.top {
  top: 0;
}

.bottom {
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
}

.avatar {
  transition: box-shadow .2s ease-in-out;
}

.avatar:not(.photo-bigger):hover {
  box-shadow: inset 0 0 20em rgba(0, 0, 0, 0.1);
}

.photo.photo-bigger {
  width: 100%;
}

.mobile-photos {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}
.mobile-photos .photo:first-child {
  grid-row: 1/3;
  grid-column: 1/3;
}

.photo.add-photo {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #E6E6E6;
  aspect-ratio: 1;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
}
.photo.add-photo i {
  font-size: 1.5rem;
  color: #359EFF;
}
.mobile-photos .photo:first-child i {
  font-size: 2rem;
} 

.photo .edit-buttons .btn {
  padding: .2em .3em .05em;
  margin: 0 .15em;
  font-size: 1.25em;
}

.photo .edit-buttons {
  width: 100%;
  opacity: 0;
  transition: all .2s ease-in-out;
}

.photo:hover .edit-buttons {
  opacity: 1;
}

.gallery-toggle {
  cursor: pointer;
}

.swiper-gallery {
  position: relative;
  overflow: hidden;
}
</style>